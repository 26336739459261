.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

body {
  height: 100% !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.transition-3 {
  transition: all 0.33s ease;
  -webkit-transition: all 0.33s ease;
  -moz-transition: all 0.33s ease;
}

.transition-5 {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
}

/* react-chatbot-kit */
/* https://fredrikoseberg.github.io/react-chatbot-kit-docs/docs/advanced/configuring-css */
.react-chatbot-kit-chat-message-container {
  border: 1 px solid red;
}

.react-chatbot-kit-chat-input {
  border: 1 px solid red;
}

/* Base styles for pagination links */
.pagination-link {
  display: inline-block;
  padding: 5px 10px;
  margin: 2px;
  text-decoration: none;
  color: white;
}

.li.pagination.previous {
  color: #00d1b2 !important;
}

/* Styles for the current pagination link */
li.pagination-link.is-current {
  border: 1px solid #00d1b2 !important;
  background-color: #00d1b2 !important;
  color: #fff;
  border-color: #00d1b2 !important;
}

a.pagination-link {
  text-decoration: none !important;
}

.pagination-link .is-current {
  text-decoration: none !important;
  border: 1px solid #00d1b2 !important;
  border-color: #00d1b2 !important;
  color: white !important;
}

li.a.pagination-link.is-current {
  text-decoration: none;
  border: 1px solid #00d1b2 !important;
  border-color: #00d1b2 !important;
  color: white !important;
}

li.pagination-link.is-current.a {
  text-decoration: none;
  border: 1px solid #00d1b2 !important;
  border-color: #00d1b2 !important;
  color: white !important;
}

.pagination-link.is-current {
  text-decoration: none;
  border: 1px solid #00d1b2 !important;
  background-color: #00d1b2 !important;
  color: #fff;
  border-color: #00d1b2 !important;
}

a.pagination-link.is-current {
  background-color: #00d1b2;
  border-color: #00d1b2;
  color: white;
}

.pagination-list {
  display: flex;
  justify-content: center;
  align-content: center;
  text-decoration: none;
  border-color: #00d1b2 !important;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

ul.pagination-list {
  display: flex;
  justify-content: center;
  align-content: center;
  text-decoration: none;
  border-color: #00d1b2 !important;
  margin: 0 auto;
}

ul.pagination-list li {
  display: block;
}
